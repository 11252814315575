
:global(#root) .powerOn {
    border: 2px solid #555;
    /* border-radius: 5px; */

    height: 10em;
    text-align: center;
    padding-top: 4em;

}

:global(#root) .clearfix::after {
    content: "";
    display: block;
    clear: both;
}

:global(#root) .imageContainer {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

:global(#root) .imageContainer img {
    flex: 1;
    width: 0;
    object-fit: cover;
    object-position: top;
    display: block;
}

:global(#root) .steps p {
    /* max-width: 40em; */
}

:global(#root) .steps :global(.step):last-of-type::after {
    display: none;
}

:global(#root) .steps .navButton {
    border-radius: 0;
}

:global(#root) .steps :global(.disabled) {
    background-color: rgb(243, 244, 245);
}

:global(#root) .bottomMargin {
    margin-bottom: 5em;
}

:global(#root) .spacer {
    height: 2em;
}

:global(#root) .nextButton {
    width: 60%;
    margin-left: calc(20%);
    text-align: center;
}

:global(#root) .instructionCard {
    display: flex;
    gap: 1em;
    padding: 1em 0;
}

:global(#root) .instructionCard .instructionImage {
    flex: 1;
    width: 0;
}

:global(#root) div.hr {
    border-bottom: 1px solid #ccc;
    content: '';
}

:global(#root) .instructionCard .instructionCardText {
    flex: 1.25;
    font-size: 1.3em;
    padding-top: 2em;
    padding-right: 3em;
    margin-bottom: 2em;
}
:global(#root) .instructionCard .instructionCardText h3 {
    font-size: 1.3em;
}

@media only screen and (max-width: 991px) {
    :global(#root) .instructionCard {
        flex-direction: column;
    }
    :global(#root) .instructionCard .instructionImage {
        width: auto;
        order: 2;
    }
    :global(#root) .nextButton {
        width: 100%;
        margin-left: 0;
        text-align: center;
        border-radius: 0.5em;
    }
}