:global(#root) .backgroundColorOverride {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3398d4;
    /* background-color: #c5dae7; */
    /* background-color: #d1d6d9; */
}

:global(#root) .container {
    padding-top: 2em;
    margin-bottom: 2em;
}
