
:global(#root) .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

:global(#root) .footer :global(.ui.grid),
:global(#root) .footer :global(.row),
:global(#root) .footer :global(.column) {
    margin: 0;
    padding: 0;
}

:global(#root) .footerSegment {
    padding-right: 13em;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}