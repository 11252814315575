:global(#root) .menuPadding {
    padding-top: 6em;
}

:global(#root) .logo {
    height: 1em;
    width: auto;
}

:global(#root) .dropdownGroup {
    display: flex;
}
:global(#root) .dropdownGroup > :global(div.text) {
    order:2;
}
:global(#root) .dropdownGroup > :global(i.icon) {
    margin-right: 0.2em;
}

:global(#root) .navbar .hamburger {
    display: none;
}

@media only screen and (max-width: 991px) {
    :global(#root) .navbar .menuItem {
        display: none;
    }

    :global(#root) .navbar .hamburger {
        display: flex;
    }
    :global(#root) .navbar .hamburger .menuItem {
        display: flex;
    }
    :global(#root) .navbar .hamburger i {
        margin: 0;
    }
}