.maxLengthLabel:global(.ui.label) {
    display: block;
    float: right;
    margin-top: -0.5em;
}

:global(.error) + .maxLengthLabel:global(.ui.label) {
    margin-top: -3.8em;
}

.clearfix {
    content: "";
    clear: both;
    display: table;
}

:global(.ui.form) .textarea textarea {
    max-height: none;
}

.container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 1em;
    visibility: hidden;
}

.button {
    visibility: visible;
}