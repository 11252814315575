
@font-face {
    font-family: '5x8';
    src: url('../fonts/5x8/5x8_lcd_hd44780u_a02.woff2') format('woff2'),
        url('../fonts/5x8/5x8_lcd_hd44780u_a02.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

:global(#root) .container {
    border-width: 2.5em 1.2em;
    border-color: #002;
    border-style: solid;
    border-radius: 0.4em;

    width: 35.5em;
    height: 12em;

    /* margin: 0 auto; */
    margin-bottom: 1em;
    margin-top: 1em;

    background-color: #3b5bff;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

:global(#root) .line {
    margin: 0.35em 0.55em;
    font-size: 2.5em;
    font-family: "5x8", monospace;
    color: white;
}

@media only screen and (max-width: 560px) {
    :global(#root) .container {
        font-size: 0.5em;
        height: 13em;
    }
}