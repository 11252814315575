:global(#root) .outerSegment {
    position: relative;
}

:global(#root) .deviceIcon {
    height: 0.9em;
    width: auto;
    margin-right: 0.5em;
    vertical-align: baseline;
}

:global(#root) .deviceProductName {
    color: #999;
    font-size: 0.75em;
    margin-left: 0.5em;
}

/* Settings */
:global(#root) .settingsContainer {
    display: block;
    position: absolute;
    top: 0.4em;
    right: 0.5em;
    cursor: pointer;
}

:global(#root) .settingsText {
    color: #999;
    user-select: none;
    margin-right: 0.4em;
    vertical-align: bottom;
}

/* Table Heading */
:global(#root) .tableHeadingRow th:nth-child(1) {
    width: 30%;
}

:global(#root) .tableHeadingRow th:nth-child(2) {
    width: 20%;
}

:global(#root) .tableHeadingRow th:nth-child(3) {
    width: 30%;
}

:global(#root) .tableHeadingRow th:nth-child(4) {
    width: 20%;
}

/* Table Data */
:global(#root) .tableRow {
    cursor: pointer;
}

:global(#root) .symbolName {
    margin-right: 0.5em;
}

:global(#root) .symbolError {
    color: #dbb119;
}

:global(#root) .symbolSubtext {
    color: #999;
    font-size: 0.8em;
}

:global(#root) .lotCell {
    padding-left: 3em;
}
:global(#root) .actionsCell {
    padding: 0 0.6em;
    text-align: right;
}

/* Table Footer */
:global(#root) .footerRow th {
    background-color: inherit;
    background: inherit;
}

:global(#root) .numSharesInput {
    width: 100%;
}

:global(#root) .symbolInput :global(.results) {
    z-index: 2000;
}
:global(#root) .costPerShareInputWrapper {
    display: flex;
    width: 100%;
}
:global(#root) .costPerShareInput {
    flex: 1;
    flex-grow: 1;
}

:global(#root) .addRowCell {
    padding-top: 0;
    padding-bottom: 0;
    text-align: right;
}

:global(#root) .infoIconTooltipHack i {
    margin-left: -1em;
    padding-left: 1em;
}